import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

interface IRenderValuesProps {
  value: string | number | null | undefined;
  mainColor?: string;
  classes?: any;
  color: string | null;
  tstId?: string;
  formatting?(value: number): string;
}

export const obfuscateIp = (ip: string) => {
  if (/(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})($|:[0-9]+$)/gi.test(ip)) {
    const partial = ip.split(".");
    const last = partial.pop();
    const portIdx = last ? last.indexOf(":") : -1;
    return partial.join(".") + ".***" + (portIdx > -1 ? last?.substr(portIdx) : "");
  } else {
    const _ip = ip.trim().replace(/::/gi, ":").replace(/--/gi, "-");
    const dot2Len = _ip.length - _ip.replace(/:/gi, "").length;
    const dashLen = _ip.length - _ip.replace(/-/gi, "").length;
    const splitter = dot2Len > 1 ? ":" : dashLen > 1 ? "-" : ".";
    if (_ip.indexOf("[") !== -1 || _ip.indexOf("]") !== -1) {
      const res = ip.split(/\[(.*?)]/gi);
      const partial = (res[1] as string).replace(/::/gi, ":").replace(/--/gi, "-").split(splitter);
      const last = partial.pop();
      const obfuscated =
        splitter + "*".repeat(last ? (last.length < 2 ? 3 : last.length > 4 ? 4 : 3) : 0);
      return `${res[0]}[${partial.slice(0, 3).join(splitter) + obfuscated}]${res[2]}`;
    }
    const partial = _ip.split(splitter);
    const length = partial.length;
    if (length > 0) {
      const last = partial.pop();
      const obfuscated =
        splitter + "*".repeat(last ? (last.length < 2 ? 3 : last.length > 4 ? 4 : 3) : 0);
      return partial.slice(0, 3).join(splitter) + obfuscated;
    } else {
      return "***.***.***.***";
    }
  }
};

const styles = (theme: any) => ({
  value: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    display: "block",
    "& span": {
      fontSize: 14,
      fontWeight: 400,
      color: "#8b909f",
    },
  },
  error: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1,
    color: theme.customColors.errorColor,
    display: "block",
  },
  unavailable: {
    fontSize: 22,
    fontWeight: 600,
    color: "#8b909f",
    display: "block",
    lineHeight: 1,
  },
});

const RenderValuesWrapper = (props: IRenderValuesProps) => {
  const { value, classes, color, tstId } = props;

  if (
    value === undefined ||
    value === null ||
    color === null ||
    (typeof value === "number" && !Number.isFinite(value)) ||
    (typeof value === "number" && value === -1)
  ) {
    if (Number.isFinite(value) && Number(value) < 0) {
      return <span className={classes.unavailable}>N/A</span>;
    }
    return <span className={classes.value}>-</span>;
  }

  let result = value;
  if (props.formatting && typeof value === "number") {
    result = props.formatting(value);
  }
  if (typeof result === "number") {
    return (
      <span className={`${classes.value}`} style={{ color: color ? color : "" }}>
        {result}
      </span>
    );
  }
  if (result === "error") {
    return <span className={`${classes.error}`}>X</span>;
  }

  const title = result.replace(/\<.+\>(.+)\<\/.+\>/, "$1");

  return (
    <span
      title={title}
      className={`${classes.value} ${tstId || "tst-value"}`}
      style={{ color: color ? color : "" }}
      dangerouslySetInnerHTML={{ __html: result }}
    />
  );
};

const RenderValues = withStyles(styles)(RenderValuesWrapper);

export { RenderValues };
