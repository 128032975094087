import React from "react";
import "./Widget.css";
import { ErrorStateIcon } from "./Icons";

type ErrorMapping = {
  current: string;
  newTitle: string;
  newError: string;
};

const errorMappings: ErrorMapping[] = [
  {
    current: "Test failed: Please keep your browser in the foreground during the test!",
    newTitle: "Focus please",
    newError: "Please keep your browser in the foreground during the test",
  },
  {
    current: "connectionOverrideIceServers=true, Missing connection RTCIceServers",
    newTitle: "Missing TURN servers",
    newError: "There are no TURN servers configured. This test cannot be conducted",
  },
  {
    current:
      "There was an error while establishing a connection using the OpenTok library. Please retry, and if the error persists, kindly contact our support team for assistance.",
    newTitle: "Connectivity issue",
    newError: "An OpenTok error. Please retry and if issue persists, contact our support team",
  },
  {
    current:
      "There was an network error while retrieving the connection information . Please retry, and if the error persists, kindly contact our support team for assistance",
    newTitle: "Connection retrieval",
    newError: "Failed to connect. Please retry and if issue persists, contact our support team",
  },
  {
    current: "Failed to get test credentials",
    newTitle: "Bad credentials",
    newError: "Failed to get proper test credentials",
  },
  {
    current: "Failed connecting to server, rtc-100:GetConnectionInfo",
    newTitle: "Can't connect",
    newError: "Failed connecting to server, rtc-100:GetConnectionInfo",
  },
  {
    current: "Failed connecting to server, GetConnectionInfoForGenesysLogin",
    newTitle: "Can't connect",
    newError: "Failed connecting to server, GetConnectionInfoForGenesysLogin",
  },
  {
    current: "URL requested region {region} unknown, valid regions are {regions}",
    newTitle: "Invalid region",
    newError: "Region {region} is unknown...",
  },
  {
    current:
      "URL requested region [${testRunInfo.testConfig.region}] found in datacenterRegionsMap [${optimalRegionName}] but not in datacenterRegions",
    newTitle: "Region failure",
    newError: "Seems like a configuration error. Please contact our support team",
  },
  {
    current: "optimalServerByPingAll mode, not found ping results",
    newTitle: "Ping failure",
    newError: "Cannot find an optimal region because there are no ping results",
  },
  {
    current: "GetAwsOptimalServer: Missing CloudTestApi",
    newTitle: "Configuration issue",
    newError: "Missing CloudTestApi",
  },
  {
    current: "GetAwsOptimalServer: query:${config.CloudTestApi}/country return empty",
    newTitle: "Configuration issue",
    newError: "Route 53 can't figure out an optimal region to use",
  },
  {
    current: "GetAwsOptimalServer [${regionCode}] not found in testConfig.datacenterRegions",
    newTitle: "Configuration issue",
    newError: "Route 53 has a region that isn't configured for this account",
  },
  {
    current: "Can't find location",
    newTitle: "Untracable",
    newError: "Can't find the location for this device",
  },
  {
    current: "Ping list not configured, use pairsList or allList",
    newTitle: "Configuration issue",
    newError: "There are no regions, so we can't perform ping test",
  },
  {
    current: "Could not establish a connection to server within 20 seconds",
    newTitle: "Connectivity issue",
    newError: "Cannot establish a connection to server within XXX seconds",
  },
  {
    current:
      "Sorry, your browser does not fully support WebRTC. Please update to a newer version of your browser, or switch to a browser that fully supports WebRTC",
    newTitle: "WebRTC support",
    newError: "Your browser doesn't support WebRTC. You'll need to use a different one",
  },
  {
    current: "publicTurn list is empty",
    newTitle: "Missing TURN servers",
    newError: "There are no TURN servers configured. This test cannot be conducted",
  },
  {
    current: "Cannot find servers to test",
    newTitle: "Missing TURN servers",
    newError: "There are no TURN servers configured. This test cannot be conducted",
  },
];

const ErrorState = ({ error }: { error: string }) => {
  const transformError = (error: string) => {
    const mapping = errorMappings.find((map) => map.current === error);
    if (!mapping) {
      return {
        title: "Error",
        error: error,
      };
    }

    return {
      title: mapping.newTitle,
      error: mapping.newError,
    };
  };
  const transformedError = transformError(error);

  return (
    <div className="error-state-container">
      <ErrorStateIcon />
      <div className="error-state-values">
        <h3>{transformedError.title}</h3>
        <p>{transformedError.error}</p>
      </div>
    </div>
  );
};

export default ErrorState;
