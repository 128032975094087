import React from "react";
import "./Widget.css";

interface OneColumnRowProps {
  title: string;
  value?: number | string | null;
  color?: string;
  ping?: boolean;
  unit?: string;
  parentId?: string;
  childId?: string;
}

const OneColumnRow = ({
  title,
  value,
  color,
  ping = false,
  unit,
  parentId,
  childId,
}: OneColumnRowProps) => {
  return (
    <div className="oneColumn-result-row" id={parentId}>
      <span>{title}</span>
      <p style={{ color, textAlign: ping ? "right" : "left" }} id={childId}>
        {value !== null ? value : "-"} <span className="widget-ping-unit">{unit}</span>
      </p>
    </div>
  );
};

export default OneColumnRow;
