import React from "react";
import { IPing, IPingAll, ITestsResult } from "twillio-tests/core/TestResults";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import WidgetHeader from "./UI/WidgetHeader";
import { withTheme } from "@material-ui/core";
import OneColumnRow from "./UI/OneColumnRow";
import ErrorState from "./UI/ErrorState";

interface IPingTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
}

const initialValues: IPing = {
  ping: null,
  region: null,
};

const findBest = (arr: IPingAll): IPing | undefined => {
  if (Array.isArray(arr)) {
    const numbers = arr.map(({ ping }) => Number(ping));
    const best = Math.max.apply(Math, numbers);

    return arr.find((item) => {
      if (typeof item === "undefined") {
        return;
      }
      return Number(item.ping) === best;
    });
  }
};

const NewPingTest = (props: IPingTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName } =
    props;

  const pingTestResult = testResult ? testResult.pingTest : null;

  const pingTest = pingTestResult
    ? pingTestResult.best || (pingTestResult.all && findBest(pingTestResult.all))
    : initialValues;

  const currentThresholds = colorThresholds ? colorThresholds.Ping : null;

  const { ping, region, error } = pingTest;

  const defaultProps = {
    mainColor,
    color: "#243544",
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: "#243544",
  };
  const { t } = useTranslation(["common", "tests"]);

  const getColor = (value: number | string | null, threshold: any) => {
    if (threshold) {
      const color = getColorValue(
        {
          ...colorTypes,
          value,
          warningValue: threshold.warning,
          errorValue: threshold.error,
        },
        threshold.isLess
      );
      return color || defaultProps.color;
    } else {
      return defaultProps.color;
    }
  };

  return (
    <>
      <WidgetHeader
        title={t("tests:ping.title")}
        tooltip={t("tests:ping.tooltip")}
        error={error}
        knowledgeUrl={knowledgeUrl}
        estimateTime={estimateTime}
        testResult={testResult}
      />
      {error ? (
        <ErrorState error={error} />
      ) : (
        <div className="widget-ping-wrapper">
          <OneColumnRow
            title={t("tests:ping.region")}
            value={region}
            ping
            parentId="PBT-r-region"
            childId="PBT-c-region"
          />
          <OneColumnRow
            title={t("tests:ping.rtt")}
            value={ping === -1 ? "-" : formatNumber(Number(ping))}
            color={getColor(ping, currentThresholds?.ping)}
            ping
            unit="ms"
            parentId="PBT-r-ping"
            childId="PBT-c-ping"
          />
        </div>
      )}
    </>
  );
};

export default withTheme()(NewPingTest);
